/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

//// CONTROLLER ////
export class AssignDialogController {
    //Bindable
    public agencyPromise: any;
    public advertiserPromise: any;
    public brandPromise: any;
    public friendsOfPromise: any;
    public sessionData: any;
    public friendsOf: any;
    public assign: any = {};
    public disableSave: any;
    public searchAgency: any;
    public searchAdvertiser: any;
    public searchBrand: any;

    static get $inject() {
        return [
            '$filter',
            '$state',
            '$mdDialog',
            '$q',
            'AgencyResourceFactory',
            'AdvertiserResourceFactory',
            'BrandsResourceFactory',
            'SecurityAccountResourceFactory',
            'loginService',
            'SpotResourceFactory',
            'spotIsci',
        ];
    }

    constructor(
        public $filter: any,
        public $state: any,
        public $mdDialog: any,
        public $q: any,
        public AgencyResourceFactory: any,
        public AdvertiserResourceFactory: any,
        public BrandsResourceFactory: any,
        public SecurityAccountResourceFactory: any,
        public loginService: any,
        public SpotResourceFactory: any,
        public spotIsci: string
    ) {
        /* BINDABLE: DATA */
        this.agencyPromise = this.$q.defer();
        this.advertiserPromise = this.$q.defer();
        this.brandPromise = this.$q.defer();
        this.friendsOfPromise = this.$q.defer();
        this.sessionData = this.loginService.getSessionData();
        this.disableSave = true;
    }

    $onInit() {
        let vm = this;
        vm.SecurityAccountResourceFactory.findFriendOfProviders(
            { id: this.sessionData.accountId },
            (results:any) => {
                vm.friendsOf = results.data.FriendsOf;
                vm.friendsOfPromise.resolve(vm.friendsOf);
            },
            (err:any) => {
                console.log(err);
            }
        );
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    save() {
        // Convert the fully-hydrated objects to simple IDs
        this.assign.agency = (this.assign.agency && this.assign.agency.id)? this.assign.agency.id : null;
        this.assign.advertiser = (this.assign.advertiser && this.assign.advertiser.id)? this.assign.advertiser.id : null;
        this.assign.brand = (this.assign.brand && this.assign.brand.id)? this.assign.brand.id : null;

        this.$mdDialog.hide(this.assign);
    }

    loadAgencies(nameSearch:string) {
        return new Promise((resolve) => {
            this.AgencyResourceFactory.getAll(
                {
                    friendId: this.assign.friend,
                    name: nameSearch,
                    activeOnly: true,
                    limit: 15,
                }
            ).subscribe(
                (agencies:any) => {
                    resolve(agencies.rows);
                },
                (err:any) => {
                    resolve([]);
                    console.debug(err);
                }
            );
        })
    }

    changeAgency() {
        if (
            this.assign.agency === null ||
            (
                this.assign.advertiser &&
                this.assign.advertiser.agencyId !== this.assign.agency.id
            )
        ) {
            this.assign.advertiser = null;
            this.changeAdvertiser();
        }
    }

    loadAdvertisers(searchName:string) {
        console.log(this.assign);
        return new Promise((resolve) => {
            this.AdvertiserResourceFactory.getAll(
                {
                    agencyId: this.assign && this.assign.agency && this.assign.agency.id !== '' ? this.assign.agency.id : null,
                    accountId: this.assign.friend ? this.assign.friend : null,
                    active: true,
                    name: searchName,
                    limit: 15,
                }
            ).subscribe(
                (advertisers:any) => {
                    //If necessary, filtering to only advertisers with a blank Agency
                    if (!this.assign.agency || !this.assign.agency.id || this.assign.agency.id === '') {
                        advertisers = this.$filter('filter')(advertisers, {
                            agencyId: null,
                        });
                    }
                    resolve(advertisers);
                },
                (err:any) => {
                    resolve([]);
                    console.debug(err);
                }
            );
        });
    }

    changeAdvertiser() {
        // If an advertiser with no ID is selected somehow, just blow it away
        if (this.assign.advertiser && this.assign.advertiser.id === '') {
            this.assign.advertiser = null;
        }

        // If the advertiser has been cleared, or if the brand no longer matches, clear the brand out
        if (
            this.assign.advertiser === null ||
            (
                this.assign.Brand &&
                this.assign.Brand.advertiserId !== (this.assign.advertiser && this.assign.advertiser.id ? this.assign.advertiser.id : null)
            )
        ) {
            this.assign.brand = null;
            this.changeBrand();
        }
    }

    loadBrands(nameSearch:string) {
        return new Promise((resolve) => {
            if (this.assign.advertiser.id !== '') {
                this.BrandsResourceFactory.getAll(
                    {
                        advertiserId: this.assign && this.assign.advertiser ? this.assign.advertiser.id : null,
                        accountId: this.assign.friend,
                        name: nameSearch,
                        active: true,
                        limit: 15,
                    }
                ).subscribe(
                    (brands:any) => {
                        resolve(brands);
                    },
                    (err:any) => {
                        resolve([]);
                        console.debug(err);
                    }
                );
            }
        })
    }

    changeBrand() {
        // If a bad brand is selected somehow, clear it out
        if (this.assign.brand && this.assign.brand.id === '') {
            this.assign.brand = null;
        }
    }

    checkValidSelection(type:string) {
        let search = '';
        let desired = {
            name: ''
        };

        switch(type) {
            case 'agency':
                search = this.searchAgency;
                desired = this.assign.agency;
                break;
            case 'advertiser':
                search = this.searchAdvertiser;
                desired = this.assign.advertiser;
                break;
            case 'brand':
                search = this.searchBrand;
                desired = this.assign.brand;
                break;
        }

        // If search was cleared, that's fine, we've nothing to do then
        if(search === '') {
            return;
        }

        if(!desired || desired.name !== search) {
            switch(type) {
                case 'agency':
                    this.searchAgency = '';
                    this.assign.agency = null;
                    break;
                case 'advertiser':
                    this.searchAdvertiser = '';
                    this.assign.advertiser = null;
                    break;
                case 'brand':
                    this.searchBrand = '';
                    this.assign.brand = null;
                    break;
            }
        }
    }

    showAssignErrorDialog() {
        let confirm = this.$mdDialog
            .confirm({ multiple: true })
            .title('Non-Unique ISCI')
            .textContent('Target account already contains this ISCI.')
            .ariaLabel('Target account already contains this ISCI.')
            .ok('OK');

        this.$mdDialog.show(confirm);
    }

    verifyIsci() {
        this.SpotResourceFactory.verify({ isci: this.spotIsci, friendId: this.assign.friend }).subscribe(
            (result:any) => {
                if (result) {
                    this.showAssignErrorDialog();
                    this.disableSave = true;
                } else {
                    this.disableSave = false;
                }
            }
        );
    }
}
