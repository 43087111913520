/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.spots').controller('CopySpotProxyDialogController', ['$state', '$mdDialog', 'apiURL', 'NotificationService', function ($state, $mdDialog, apiURL, NotificationService) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.apiURL = apiURL;
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.copyUrl = copyUrl;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
    }

    function copyUrl() {
      var urlToCopy = document.getElementById('apiUrl');

      if (urlToCopy) {
        urlToCopy.value = vm.apiURL;
        urlToCopy.select();
        document.execCommand('Copy');
        NotificationService.showNotificationToast('Copied spot proxy link');
      } else {
        NotificationService.showNotificationToast('Could not copy spot proxy link');
      }
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish the

    /**
     * Initialization for this controller.
     */


    function _init() {}
  }]);
})();