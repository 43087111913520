/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.spots').controller('AdIdMetadataDialogController', ['$state', '$mdDialog', '$q', 'metadata', function ($state, $mdDialog, $q, metadata) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.metadata = metadata;
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.save = save;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
    }

    function save(type) {
      if (type === 'all') {
        $mdDialog.hide(vm.metadata);
      } else {
        var newMetaData = {
          isci: vm.metadata.isci,
          title: vm.metadata.title,
          expectedDuration: vm.metadata.expectedDuration,
          format: vm.metadata.format
        };
        $mdDialog.hide(newMetaData);
      }
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish the

    /**
     * Initialization for this controller.
     */


    function _init() {}
  }]);
})();